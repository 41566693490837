import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/SEO'
import { Container } from '../styles/PageLayout'
import { Headline, ParagraphM } from '../styles/Typography'

const NotFoundPage = () => (
  <>
    <SEO title="Not Found" />
    <Container column mobileColumn minHeight="75vh" gap="1.5em">
      <Headline>Oops, this page does not exist</Headline>
      <Link to="/">
        <ParagraphM>← go back home</ParagraphM>
      </Link>
    </Container>
  </>
)

export default NotFoundPage
